import "@fortawesome/fontawesome-free/css/all.min.css";
import "../styles/globals.css";

import React, { useEffect, useState } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { I18nextProvider } from "react-i18next";
import { AuthProvider } from "../src/provider/authentication";
import { theme } from "../src/theme";
import i18n from "../src/i18n";
import { PlaybackProvider } from "../src/provider/playback";
import { SharedContentProvider } from "../src/provider/sharedContent";
import TermsOfServiceModal from "../src/components/modals/tosModal";
import { DefaultLayout } from "../src/layouts/defaultLayout";
import UnderConstructionPage from "../src/layouts/under-construction-layout";

export default function App({ Component, pageProps }: AppProps) {
  // Bugfix for https://github.com/vercel/next.js/discussions/35773
  // See here: https://github.com/vercel/next.js/discussions/35773#discussioncomment-2515797
  // and here: https://github.com/chakra-ui/chakra-ui/issues/5842
  // and here: https://github.com/vercel/next.js/discussions/35773#discussioncomment-2871024
  // and here: https://nextjs.org/docs/messages/react-hydration-error
  const [isAppReady, setIsAppReady] = useState(false);
  const { isReady } = useRouter();

  useEffect(() => {
    setIsAppReady(true);
  }, []);

  if (isReady && isAppReady) {
    return (
      <React.Fragment>
        <Head>
          <meta name={"viewport"} content={"width=device-width, initial-scale=1, shrink-to-fit=no"} />
          <title>VIL Portal</title>
        </Head>

        <ChakraProvider theme={theme}>
          <I18nextProvider i18n={i18n}>
            <AuthProvider>
              <TermsOfServiceModal />
              <PlaybackProvider>
                <SharedContentProvider>
                  <Component {...pageProps} />
                  {/* <UnderConstructionPage /> */}
                </SharedContentProvider>
              </PlaybackProvider>
            </AuthProvider>
          </I18nextProvider>
        </ChakraProvider>
      </React.Fragment>
    );
  } else {
    return null;
  }
}
